@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=League+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap);
@import url(https://use.typekit.net/eza4hsv.css);
body {
  background-color: white;
}
a:hover,
a:visited,
a:link {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 767px) {
  /* Target only devices below 768px */
  .loader {
    margin-top: 0px !important;
  }
}
@media screen and (min-width: 768px) {
  /* Target only devices above 768px */
  .loader {
    margin-top: -125px !important;
  }
}

